import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Home from './components/Home';
import Pricing from './components/Pricing';
import CompanyForm from './components/CompanyForm';
import WaitlistConfirmation from './components/WaitlistConfirmation';
import './App.css';
import { useEffect } from 'react';
import { trackEvent } from './utils/analytics';

// Create a wrapper component that uses useLocation
const AppContent = () => {
  const location = useLocation();

  useEffect(() => {
    // Track page view on route change
    trackEvent('page_view', location.pathname);
  }, [location]);

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/company-form" element={<CompanyForm />} />
        <Route path="/waitlist-confirmation" element={<WaitlistConfirmation />} />
      </Routes>
    </div>
  );
};

// Main App component with Router
function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;
