import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './Pricing.css';
import { trackEvent } from '../utils/analytics';

const PricingCard = ({ title, price, description, buttonText, onClick }) => {
    return (
        <div className="pricing-card">
            <h3 className="card-title">{title}</h3>
            <div className="price-container">
                {title === 'Enterprise' ? (
                    <span className="price">Contact Sales</span>
                ) : (
                    <>
                        <span className="price">$ {price}</span>
                        <span className="period">/ month</span>
                    </>
                )}
            </div>
            <div className="team-size">
                {description}
            </div>
            <button className="start-button" onClick={onClick}>
                {buttonText}
            </button>
        </div>
    );
};

const Pricing = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const fromPage = location.state?.fromPage || 1;

    React.useEffect(() => {
        console.log('User came from page:', fromPage);
    }, [fromPage]);

    const handleStartClick = (tier) => {
        trackEvent('start_click', 'pricing', { tier: tier });
        navigate('/company-form');
    };

    const pricingPlans = [
        {
            title: 'Starter',
            price: '99',
            description: '< 10 employees',
            buttonText: 'Start',
            onClick: () => handleStartClick('starter')
        },
        {
            title: 'Growth',
            price: '499',
            description: '< 100 employees',
            buttonText: 'Start',
            onClick: () => handleStartClick('growth')
        },
        {
            title: 'Enterprise',
            price: '',
            description: '> 100 employees',
            buttonText: 'Start',
            onClick: () => handleStartClick('enterprise')
        }
    ];

    return (
        <div className="pricing-page">
            <div className="pricing-container">
                <div className="pricing-cards">
                    {pricingPlans.map((plan, index) => (
                        <PricingCard key={index} {...plan} />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Pricing;
