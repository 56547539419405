import React, { useState, useEffect } from 'react';
import { supabase } from '../supabase';
import './WaitlistConfirmation.css';

const WaitlistConfirmation = () => {
    const [waitlistPosition, setWaitlistPosition] = useState(null);

    useEffect(() => {
        const fetchPosition = async () => {
            try {
                const { count, error } = await supabase
                    .from('waitlist')
                    .select('*', { count: 'exact' });

                if (error) throw error;
                
                setWaitlistPosition(count);
            } catch (error) {
                console.error('Error fetching position:', error);
                setWaitlistPosition(0);
            }
        };

        fetchPosition();
    }, []);

    return (
        <div className="confirmation-page">
            <div className="confirmation-container">
                <h2 className="single-line">We're excited to work closely with your team!</h2>
                <div className="position-indicator">
                    <div className="position-number">{waitlistPosition || '...'}</div>
                    <div className="position-label">Your position on waitlist</div>
                </div>
                <div className="confirmation-content">
                    <p className="main-message">
                        Due to high demand, we're currently managing a waitlist to ensure we can provide the best possible service to each team
                    </p>
                </div>
            </div>
        </div>
    );
};

export default WaitlistConfirmation; 