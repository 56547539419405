import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { supabase } from '../supabase';
import './CompanyForm.css';
import { trackEvent } from '../utils/analytics';

const CompanyForm = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        companyName: '',
        employeeCount: '',
        email: ''
    });
    const [emailError, setEmailError] = useState('');
    const [employeeCountError, setEmployeeCountError] = useState('');
    const [companyNameError, setCompanyNameError] = useState('');

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        switch (name) {
            case 'employeeCount':
                // Only allow numeric characters and empty string
                const numericValue = value.replace(/[^0-9]/g, '');
                setFormData({
                    ...formData,
                    employeeCount: numericValue
                });
                setEmployeeCountError('');
                break;

            case 'email':
                setFormData({
                    ...formData,
                    email: value
                });
                setEmailError('');
                break;

            case 'companyName':
                setFormData({
                    ...formData,
                    companyName: value
                });
                setCompanyNameError('');
                break;

            default:
                setFormData({
                    ...formData,
                    [name]: value
                });
                break;
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Track form submission attempt
        trackEvent('form_submit_attempt', 'company_form');

        // Validate company name
        if (!formData.companyName.trim()) {
            setCompanyNameError('Company name is required');
            trackEvent('form_validation_error', 'company_form', { error: 'company_name_empty' });
            return;
        }

        if (!validateEmail(formData.email)) {
            setEmailError('Please enter a valid email address');
            trackEvent('form_validation_error', 'company_form', { error: 'invalid_email' });
            return;
        }

        // Validate employee count
        const employeeCount = parseInt(formData.employeeCount);
        if (isNaN(employeeCount) || employeeCount < 1 || !Number.isInteger(employeeCount)) {
            setEmployeeCountError('Number must be greater than 0');
            trackEvent('form_validation_error', 'company_form', { error: 'invalid_employee_count' });
            return;
        }

        try {
            // Insert data into Supabase
            const { data, error } = await supabase
                .from('waitlist')
                .insert([
                    {
                        company_name: formData.companyName,
                        employee_count: employeeCount,
                        email: formData.email,
                        created_at: new Date().toISOString()
                    }
                ])
                .select();

            if (error) {
                console.error('Supabase error:', error);
                trackEvent('form_submit_error', 'company_form', { error: error.message });
                return;
            }

            // Track successful submission
            trackEvent('form_submit_success', 'company_form', {
                employee_count: employeeCount
            });

            console.log('Form submitted successfully:', data);
            navigate('/waitlist-confirmation');
        } catch (error) {
            console.error('Error submitting form:', error);
            trackEvent('form_submit_error', 'company_form', { error: error.message });
        }
    };

    const handleKeyDown = (e) => {
        // Allow: backspace, delete, tab, escape, enter, decimal point
        const allowedKeys = ['Backspace', 'Delete', 'Tab', 'Enter', 'ArrowLeft', 'ArrowRight'];

        // Allow numbers 0-9
        const isNumber = /^[0-9]$/.test(e.key);

        // Prevent input if it's not a number or not an allowed key
        if (!isNumber && !allowedKeys.includes(e.key)) {
            e.preventDefault();
        }
    };

    return (
        <div className="form-page">
            <div className="form-container">
                <h2>Company Details</h2>
                    <div className="form-group">
                        <input
                            className={`form-input ${companyNameError ? 'error' : ''}`}
                            type="text"
                            name="companyName"
                            value={formData.companyName}
                            onChange={handleChange}
                            placeholder="Company name"
                            required
                        />
                    </div>
                    {companyNameError && <div className="error-message">{companyNameError}</div>}
                    <div className="form-group">
                        <input
                            className={`form-input ${employeeCountError ? 'error' : ''}`}
                            type="number"
                            name="employeeCount"
                            value={formData.employeeCount}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            placeholder="Number of employees"
                            min="1"
                            required
                        />
                    </div>
                    {employeeCountError && <div className="error-message">{employeeCountError}</div>}
                    <div className="form-group">
                        <input
                            className={`form-input ${emailError ? 'error' : ''}`}
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            placeholder="Email"
                            required
                        />
                    </div>
                    {emailError && <div className="error-message">{emailError}</div>}
                    <div onClick={handleSubmit} className="form-submit-btn">
                        <div>
                            Next
                        </div>
                    </div>
            </div>
        </div>
    );
};

export default CompanyForm; 