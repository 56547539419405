import { supabase } from '../supabase';

export const trackEvent = async (eventType, page, details = {}) => {
    try {
        // Get or create session ID from localStorage
        let sessionId = localStorage.getItem('session_id');
        if (!sessionId) {
            sessionId = Math.random().toString(36).substring(2, 15);
            localStorage.setItem('session_id', sessionId);
        }

        const { error } = await supabase
            .from('stats')
            .insert([
                {
                    event_type: eventType,
                    page: page,
                    details: details,
                    session_id: sessionId
                }
            ]);

        if (error) {
            console.error('Error tracking event:', error);
        }
    } catch (error) {
        console.error('Error tracking event:', error);
    }
}; 