import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useWindow from '../hooks/useWindow';
import ReactFullpage from '@fullpage/react-fullpage';
import logo from '../assets/logo.svg';
import arrow from '../assets/arrow_down.svg';
import arrowRight from '../assets/arrow-right.svg';
import arrowWhite from '../assets/arrow-white.svg';
import plus from '../assets/plus.svg';
import done from '../assets/done.svg';
import dashboard from '../assets/dashboard.svg';
import taskManagementSystems from '../assets/task-management-systems.svg';
import './Home.css';
import { trackEvent } from '../utils/analytics';

const Home = () => {
    const { width } = useWindow();
    const navigate = useNavigate();

    const handleStartClick = (buttonId) => {
        trackEvent('start_click', 'home', { button_location: buttonId });
        navigate('/pricing');
    };

    return (
        <ReactFullpage
            scrollingSpeed={1000}
            render={() => (
                <ReactFullpage.Wrapper>
                    <div className="section">
                        <div className="App">
                            <header className="header">
                                <img src={logo} className="logo page-1-margin-top" alt="logo" />
                                <div className="text page-1-margin-top">
                                    Performance-driven bonuses
                                </div>
                                <div className="btn page-1-margin-top" onClick={() => handleStartClick('page1_main')}>
                                    <div style={{ pointerEvents: 'none' }}>Start</div>
                                </div>
                                <div className="small-text page-1-margin-top page-1-small-text">
                                    Empower your team with <span className="text-600">motivation</span> and{' '}
                                    <span className="text-600">appreciation</span>
                                    <br />
                                    <div className="text-600 margin-top-1vh">EVERY DAY</div>
                                </div>
                                <img src={arrow} className="arrow" alt="arrow" />
                            </header>
                        </div>
                    </div>
                    <div className="section">
                        <div className="App" style={{ background: 'white', height: '100vh', }}>
                            <header className="header">
                                <div className='text-medium text-medium-weight' style={{ marginTop: '3vh' }}>
                                    Directly<div className='text-700'>from Result to Reward </div>
                                </div>
                                <div className='arrow-container page-2-margin-top'>
                                    <div className='from-to'>
                                        <text className='text-600'>Complete task</text><div className='or-and text-400'>or</div><text className='text-600'>Move metric</text>
                                    </div>
                                    <div className='arrow-right-container'>
                                        <img src={arrowRight} className="arrow-right" />
                                    </div>
                                    <div className='from-to'>
                                        <text className='text-600'>Get bonus</text>
                                    </div>
                                </div>
                                <div className='btn page-2-margin-top' onClick={() => handleStartClick('page2_after_arrow')}>
                                    <div style={{ pointerEvents: 'none' }}>
                                        Start
                                    </div>
                                </div>
                                <div className='small-text small-text-2-page'>
                                    <text className='text-struck-out'>Spend money on bonuses</text>
                                    <div className='text-600'>Generate more revenue through bonuses</div>
                                </div>
                                <img src={arrow} className="arrow" alt="arrow" />
                            </header>
                        </div>
                    </div>
                    <div className="section">
                        <div className="App" style={{ background: 'white', height: '100vh', }}>
                            <header className="header">
                                <div className='arrow-container-2 from-to-first'>
                                    <div className='from-to from-to-2'>
                                        <text className='text-600'>Better results</text>
                                    </div>
                                    <div className='arrow-right-container'>
                                        <img src={arrowRight} className="arrow-right arrow-right-2" />
                                    </div>
                                    <div className='from-to from-to-2'>
                                        <text className='text-600'>More revenue</text>
                                    </div>
                                </div>
                                <div className='arrow-container-2'>
                                    <div className='from-to from-to-2'>
                                        <text className='text-600'>Start Now</text>
                                    </div>
                                    <div className='arrow-right-container'>
                                        <img src={arrowRight} className="arrow-right arrow-right-2" />
                                    </div>
                                    <div className='from-to from-to-2'>
                                        <text className='text-600 text-5vh from-to-2'>Optimize for</text>
                                        <div className='text-500 text-4vh margin-top-3vh from-to-small'>Productivity boost</div>
                                        <div className='text-400 text-3-5vh margin-top-2vh and'>and</div>
                                        <div className='text-500 text-4vh margin-top-2vh from-to-small'>$ gain {'>'} $ spend</div>
                                    </div>
                                </div>
                                <div className='btn margin-top-1vh btn-page-3' onClick={() => handleStartClick('page3_after_revenue')}>
                                    <div style={{ pointerEvents: 'none' }}>
                                        Start
                                    </div>
                                </div>
                                <div className='small-text' style={{ marginTop: '8vh' }}>
                                    How it works
                                </div>
                                <img src={arrow} className="arrow" alt="arrow" />
                            </header>
                        </div>
                    </div>
                    <div className="section">
                        <div className="App black-back" style={{ height: '100vh', }}>
                            <header className="header">
                                <div className='how-container'>
                                    <div className='half-container'>
                                        <div className='half-half-container'>
                                            Create bonus
                                            <img src={plus} className="how-icon" />
                                        </div>
                                        <img src={arrowWhite} className="how-icon arrow-white" />
                                        <div className='half-half-container'>
                                            <div className='margin-top-4-75vh'>
                                                Link URL to your task management system
                                            </div>
                                            <img src={taskManagementSystems} className="how-icon" />
                                            <div className='any-other'>or any other</div>
                                        </div>
                                    </div>
                                    {width <= 600 && <img src={arrowWhite} className="how-icon arrow-white" />}
                                    <div className='half-container'>
                                        <div className='half-half-container'>
                                            Employee claims bonus after completion
                                            <img src={done} className="how-icon" />
                                        </div>
                                        <img src={arrowWhite} className="how-icon arrow-white" />
                                        <div className='half-half-container'>
                                            Manage bonuses on your dashboard
                                            <img src={dashboard} className="how-icon" />
                                        </div>
                                    </div>
                                </div>
                                <div className='btn-white' onClick={() => handleStartClick('page4_after_how')}>
                                    <div style={{ pointerEvents: 'none' }}>
                                        Start
                                    </div>
                                </div>
                            </header>
                        </div>
                    </div>
                </ReactFullpage.Wrapper>
            )}
        />
    );
};

export default Home; 